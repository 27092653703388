import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

export default () => (
  <Wrap>
    <h2>How To Care For Your Rolex:</h2>
    <div css={`display:flex;justify-content:center;`}>
      <iframe title={'youtube video embed'} css={`border:0;${md}{width:100%;}`} width="560" height="315" src="https://www.youtube.com/embed/sl24KdsiN3w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </Wrap>
)

const Wrap = styled.div`
    background-color:${colors.greyLight};
    padding: 4rem;
    text-transform:uppercase;

    h2 {
      color: ${colors.green};
      text-align:center;
    }

    ${md} {
      padding:1rem;
    }
`
