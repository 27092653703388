import React, { useState } from 'react'
import { navigate } from '@reach/router' 
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/watch-greenBar4'
import Video from '../sections/rolexCareVideo'
import { PageHead, GreenBtn, colors, md } from '../common'
import Modal from '../components/modal'
import Contact from '../components/contactForm'

export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }

  return(
  <Layout>
    <SEO
      title="Watch Repair"
      description="Underwood’s in Jacksonville and Ponte Vedra, FL is an authorized Rolex service center with two certified watchmakers. We also provide services such as watch batteries, watch sizing and more." />
    <PageHead>
      <h1>WATCH REPAIR</h1>
      <p>Underwood’s is fortunate to have a watch repair shop designed and outfitted by Rolex Watch Co. Underwood’s watch technicians are certified as either CW21 or SAWTA, the two highest level designations in the world, denoting a high level of expertise in their craft. Our watch shop specializes in the servicing and repair of Rolex watches using only authorized repair parts and tools for every job. Our watchmakers have received extensive training on all caliber movements offered by Rolex and perfect their trade with continuing education on an annual basis. Both of our watchmakers are extremely skilled and will handle your watch with the utmost care.</p>
      <p>Just like a fine car, a Rolex watch can last a lifetime if it is serviced with the correct equipment and replacement parts on a regular basis. If not serviced, it can prematurely wear to the point that it can no longer be repaired. Our technicians will remove the movement of your watch, clean each individual part with the proper oils, replace any worn parts, reassemble your watch while adjusting the timekeeping and then refinish the case and each link with a polish that will make your watch look close to brand new. After servicing, your Rolex will have a 2-year warranty on the inside movement. Prices for an overhaul start at $750 and depend on the model of your watch. If you purchased your Rolex from us all future services will be 10% off. The average time frame an overhaul takes to be completed is 10-15 business days*. You can take your Rolex to any of our locations to be serviced.</p>
      <p css={`font-size:12px;`}>*Subject to customer approval and availability of parts.</p>
      <GreenBtn onClick={handleClick}>Book An appointment</GreenBtn>
    </PageHead>
    <GreenBar />
    <WatchCards />
    <Video />
    <JoinUs />
    <Modal show={ show } toggle={ handleClick }>
      <Contact />
    </Modal>
  </Layout>
)}

export const WatchCards = () => (
  <CardWrap>
    <Card>
      <img src="https://s3.amazonaws.com/underwoods/Watch+Repair/watchrepair02a.jpg" alt ="watch band repair" />
      <h3>WATCH SIZING</h3>
      <p>When you wear a watch that does not fit your wrist it can cause damage. If you purchased your watch from Underwood’s we will size your bracelet at any time for free. If you purchased your watch elsewhere, we charge $10-50 depending on the style. We do our best to size watch bracelets within 24 hours of your bringing your watch to us. This is subject to staff availability and parts; therefore, you may have to leave your watch with us for a few days*. If you need extra links we can order them from any of the manufacturers we carry (Michele Watch, David Yurman Timepieces, Tavannes, Seiko, Raymond Weil, Tudor and Rolex).
      <br /><br /><span css={`font-size:12px;`}>*Subject to customer approval and availability of parts.</span></p>
    </Card>
    <Card>
      <img src="https://s3.amazonaws.com/underwoods/Watch+Repair/watchrepair01a.jpg" alt ="watch battery replacement" />
      <h3>WATCH BATTERIES</h3>
      <p>Underwood’s can change the battery in most watches. The price for a battery change is $10-$35*. While we do our best to change batteries within 24 hours, this is subject to staff availability. You can take your watch to any of our locations for a battery change.
      <br /><br /><span css={`font-size:12px;`}>*Subject to customer approval and availability of parts. If you need a battery for a watch brand we don’t carry, we cannot guarantee the water resistance if the gasket breaks during the change.</span></p>
    </Card>
    <Card>
      <img src="https://s3.amazonaws.com/underwoods/Watch+Repair/watchrepair03a.jpg" alt ="watch band replacement" />
      <h3>WATCH STRAPS/BRACELETS</h3>
      <p>In our humid Florida climate many leather straps cannot hold up to the rigors of wear for very long. We have a variety of watch straps in all of our stores in different sizes.
      <br /><br />Basic leather straps start at $20, but can go up to $500 for some of the Swiss brands.</p>
    </Card>
  </CardWrap>
)

export const CardWrap = styled.div`
  background-color:${colors.greyLight};
  display:flex;
  justify-content: space-around;
  padding: 4rem 2rem 0 2rem;
  flex-wrap:wrap;

  ${md} {
    padding:2rem;
    flex-direction:column;
  }
`

export const Card = styled.div`
  width: 30%;
  background-color:white;
  text-align:center;
  margin-bottom:2rem;

  img {
    width:100%
  }

  p {
    padding: 2rem;
    color:${colors.greyDarker};
    line-height:24px;
    margin:0;
  }

  h3 {
    color:${colors.green};
  }

  ${md} {
    width: 100%;
  }
`
